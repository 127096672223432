<template>
  <div>
    <h3 class="mb-4 color-primary-light">Please select your {{this.path[1] === "revive-dyson" ? "Dyson" : "Apple"}} product from the list below</h3>
    <div class="row">
      <div
        class="col-md-4 col-sm-6 col-xs text-center"
        v-for="(device, index) in devices"
        :key="index"
      >
        <router-link :to="getRoute(device.route)">
          <div class="image-container">
            <img :src="require('@/assets/images/devices/' + index + '.png')" v-bind:style="deviceStyling" alt="iPhone" />
          </div>
          <h3>{{ device.name }}</h3>
          <p v-html="device.label">{{ device.label }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { pages, devices } from '@/shared';

export default {
  data() {
    let deviceStyling = {}
    if(this.$route.fullPath === '/revive-dyson/select-device') {
      deviceStyling = { height: "190px" }
    }
    return {
      path: this.$route.fullPath.split('/'),
      deviceStyling
    }
  },
  computed: {
    devices() {
      if (this.$route.fullPath === '/revive-dyson/select-device') {
        return devices.dyson;
      }
      return devices.apple;
    },
  },
  methods: {
    getRoute(device) {
      if (!device) return '';
      if (this.$route.fullPath === '/revive-dyson/select-device') {
        if(pages['revive-dyson'].children.packageOption.devices.includes(device)) {
          return pages['revive-dyson'].children.packageOption.path.replace(':device', device);
        }
        return pages['revive-dyson'].children.packageInstructions.path.replace(':device', device);
      }
      return pages['revive-apple'].children.removeDevice.path.replace(':device', device);
    },
  },
};
</script>

<style scoped lang="scss">
%link-color {
  color: #482080;
}

.image-container {
  max-width: 250px;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  border: 2px solid transparent;
  border-radius: 25px;

  img {
    height: 100%;
    max-width: 100%;
  }
}

a {
  @extend %link-color;
  &:hover {
    @extend %link-color;
    text-decoration: none;

    .image-container {
      border-color: #482080;
    }
  }
}
</style>
